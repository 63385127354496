/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import parse from 'html-react-parser';
import cnames from 'classnames';

import { Samples } from './Samples';
import { fetchResults, parseContent } from './function';
import { defaultResources, resources, wmoNumbersArray } from './consts';

import './styles.scss';

export const Chat: React.FC = () => {
  const textRef = useRef<HTMLTextAreaElement>(null);
  const [report, setReport] = useState<{ name: string, answer: string, isOpen: boolean }[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [inputNoValue, setInputNoValue] = useState('');
  const [topKValue, setTopKValue] = useState(30);
  const [question, setQuestion] = useState('');
  const [disable, setDisable] = useState(false);
  const [sampleClick, setSampleClick] = useState(false);
  const [selectedOption, setSelectedOption] = useState('gpt-4');
  const [selectedResource, setSelectedResource] = useState<string[]>(defaultResources.map(x => x.key));
  // const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [isResourceOpen, setIsResourceOpen] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { instance } = useMsal();


  // eslint-disable-next-line no-constant-condition
  if (window.location.href.indexOf('chatsustainableenergy') > -1) {
    return <h1 className='my-5 w-100 text-center'>Coming soon...</h1>;
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!disable) {
      setInputValue(e.target.value);
      e.target.style.height = 'auto';
      e.target.style.height = e.target.scrollHeight + 'px';
      setSampleClick(false);
    }
  };

  const wmoNoChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputNoValue(e.target.value.trim());
    if (e.target.value.trim().length > 0) {
      setSelectedResource([]);
    } else {
      setSelectedResource(defaultResources.map(x => x.key));
    }
  };

  const topKHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTopKValue(parseInt(e.target.value, 10));
  };

  const onSubmit = async () => {
    if (!disable) {
      setDisable(true);
      setReport([]);
      setQuestion('');
      // if (selectedOption === 'gpt-4' && !instance.getActiveAccount()) {
      //   const errorText = 'You need to log in to access the GPT-4 feature.';
      //   setReport([{ name: 'Error', answer: errorText, isOpen: true }]);
      //   setDisable(false);
      //   return;
      // }

      if (inputValue.length === 0) {
        const errorText = 'Enter the question field.';
        setReport([{ name: 'Error', answer: errorText, isOpen: true }]);
        setInputValue('');
        setDisable(false);
        return;
      }

      if (selectedResource.length === 0 && inputNoValue.length === 0) {
        const errorText = 'Please select at least one resource.';
        setReport([{ name: 'Error', answer: errorText, isOpen: true }]);
        setDisable(false);
        return;
      }

      if (inputNoValue.length > 0) {
        const inputNumber = parseInt(inputNoValue, 10);
        if (isNaN(inputNumber) || !wmoNumbersArray.includes(inputNumber)) {
          const errorText = 'WMO No. is not correct.';
          setReport([{ name: 'Error', answer: errorText, isOpen: true }]);
          setDisable(false);
          return;
        }
      }

      if (selectedOption === 'gpt-3.5-turbo') {
        await setTopKValue(10);
      }

      try {
        const fetchedReport = await fetchResults(
          inputValue,
          selectedOption,
          selectedResource,
          inputNoValue,
          selectedOption === 'gpt-3.5-turbo' ? 10 : topKValue,
        );
        setReport((prev) => [...prev, ...fetchedReport]);
        setQuestion(inputValue);
        setIsResourceOpen(false);
        setInputValue('');
      } catch (error) {
        setInputValue('');
        console.error(error);
      }

      setDisable(false);
    }
  };

  const toggleOpen = (index: number) => {
    const theReport = report[index];
    theReport.isOpen = !theReport.isOpen;
    setReport((prev) => {
      const newReport = [...prev];
      newReport[index] = theReport;
      return newReport;
    });
  };

  const onEnterPress = (e: any) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      onSubmit();
    }
  };

  const sampleHandler = (text: string) => {
    setInputValue(text);
    setTimeout(() => {
      if (textRef.current) {
        textRef.current.style.height = 'auto';
        textRef.current.style.height = textRef.current.scrollHeight + 'px';
      }
    }, 100);
    setSampleClick(true);
  };

  const toggleResource = (resource: string) => {
    setSelectedResource(prevArray => {
      const index = prevArray.indexOf(resource);
      if (index !== -1) {
        // String found, remove it
        const newArray = [...prevArray];
        newArray.splice(index, 1);
        return newArray;
      } else {
        // String not found, add it
        return [...prevArray, resource];
      }
    });
  };

  // const toggleAllResource = () => {
  //   if (!selectedAll) {
  //     setSelectedResource(resources.map(x => x.key));
  //   } else {
  //     setSelectedResource([]);
  //   }
  //   setSelectedAll(prev => !prev);
  // };

  // Function to chunk the array into smaller arrays of at most size n
  const chunkArray = (array: any[], size: number) => array.reduce((chunks, item, i) => {
    if (i % size === 0) {
      chunks.push([item]);
    } else {
      chunks[chunks.length - 1].push(item);
    }
    return chunks;
  }, []);

  // Chunking the resources array into arrays of at most 5 elements each
  const chunkedResources = chunkArray(resources, 5);

  useEffect(() => {
    if (sampleClick) {
      onSubmit();
    }
  }, [sampleClick]);

  return (
    <div className="chat-window d-flex flex-column h-100 justify-content-between">
      <div className='chat-window__body h-100 d-flex flex-column overflow-hidden'>
        <div className='col-lg-9 mx-2 mx-lg-auto'>
          <div className="header fs-3 fw-bold">Ask Questions from WMO Sources</div>
          <div className="text-center center-align align-center">
            <div className='my-2'>
              <div
                className={ cnames(
                  'resources text-start px-2',
                  isResourceOpen ? 'overflow-auto' : 'overflow-hidden h-0',
                ) }
              >
                <div className='d-flex col-md-12 gap-md-4 flex-column flex-md-row justify-content-between'>
                  <input
                    id='wmo-no'
                    className="wmo-no form-control form-text fs-5 mb-3"
                    type='text'
                    placeholder="WMO-No. (e.g., 1236, 315)"
                    onChange={ wmoNoChangeHandler }
                    value={ inputNoValue }
                  />

                  <div className='d-flex me-2 gap-2 align-items-center select-group justify-content-between'>
                    <span className='fs-5 form-check-label'>Retrieve Top k Matches</span>
                    <select
                      id='top-k'
                      className="top-k form-control form-select fs-5 mb-3"
                      onChange={ topKHandler }
                      value={ topKValue }
                    >
                      <option value="10">10</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                    </select>
                  </div>

                  <div className='d-flex me-2 gap-2 align-items-center select-group justify-content-between'>
                    <span className='fs-5 form-check-label'>GPT Model</span>
                    <select
                      id='gpt-model'
                      className="form-control form-select fs-5 mb-3"
                      value={ selectedOption }
                      onChange={ (e) => setSelectedOption(e.target.value) }
                    >
                      <option value="gpt-3.5-turbo">gpt-3.5-turbo</option>
                      <option value="gpt-4">gpt-4</option>
                    </select>
                  </div>
                </div>
                { /* <div className='d-flex justify-content-between align-content-center align-items-center'>
                  <div
                    className='form-check form-check-inline text-start fs-5'
                  >
                    <input
                      className='form-check-input'
                      type="checkbox"
                      id={ 'select-all' }
                      name={ 'select-all' }
                      value={ 'select-all' }
                      checked={ selectedAll }
                      onChange={ () => toggleAllResource() }
                    />
                    <label
                      className='form-check-label fw-bold'
                      htmlFor={ 'select-all' }
                    >{ 'Select All' }
                    </label>
                  </div>
                </div> */ }
                <div className='d-flex gap-lg-3 flex-sm-row flex-column gap-2 justify-content-between'>
                  { chunkedResources.map((chunk: any[], index: number) => (
                    <div
                      key={ index + 'parent' }
                      className="parent-div align-items-start d-flex flex-column gap-2 fs-5"
                    >
                      { chunk.map((resource) => (
                        <div
                          key={ resource.key }
                          className={ cnames(
                            'form-check form-check-inline text-start',
                            { 'checked': selectedResource.includes(resource.key) },
                          ) }
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={ resource.key }
                            name={ resource.key }
                            value={ resource.key }
                            checked={ selectedResource.includes(resource.key) }
                            onChange={ () => toggleResource(resource.key) }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={ resource.key }
                          >
                            { resource.name }
                          </label>
                        </div>
                      )) }
                    </div>))
                  }
                </div>
              </div>
              <hr className='rounded-1 my-1' />
              <div onClick={ () => setIsResourceOpen(prev => !prev) }>
                { isResourceOpen ? (
                  <i className="bi bi-caret-up-fill" />
                ) : (
                  <i className="bi bi-caret-down-fill" />
                ) }
              </div>
            </div>
            <div>

              { /* {
                report.length > 0 &&
               (<h6 className="responses-header text-start mt-4">Responses:</h6>)
              } */ }
              { disable && (
                <div className="text-start mt-2 fs-5">
                  { /* <Spinner
                    animation="border"
                    role="status"
                  // variant="info"
                  /> */ }
                  <p className='loading-text fw-bold'>
                    {
                      selectedOption === 'gpt-3.5-turbo' ?
                      // eslint-disable-next-line max-len
                        'Please note that this is only a demo, it takes 20 seconds for gpt-3.5 engine to answer the questions.' :
                      // eslint-disable-next-line max-len
                        'Please note that this is a pilot project with limited resources (300 PDFs). It takes 50 seconds to answer questions.'
                    }
                  </p>
                  <p className='placeholder-glow'>
                    <span className="placeholder col-6" />
                  </p>
                  <hr />
                  <p className='placeholder-glow'>
                    <span className="placeholder col-4" />
                  </p>
                  <hr />
                </div>
              ) }
            </div>
          </div>
        </div>
        <div className="col-lg-9 mx-2 mx-lg-auto overflow-auto flex-fill">
          {
            report.length > 0 &&
            (
              <div className='answers'>
                <div className='fs-3 fw-bold mb-3 header'>{ question }</div>
                {
                  report.map((item, index) => (
                    !item.name
                    // || item.answer === 'There is not enough info to answer the question.' 
                      ? null :
                      <div
                        key={ index.toString() + 'item' }
                        className="answer-org"
                      >
                        <div
                          className='answer-title fs-5'
                          onClick={ () => toggleOpen(index) }
                        >
                          <span className='fw-bold'>
                            { resources.find(x => x.key === item.name)?.name || item.name }
                          </span>
                          { item.isOpen ? (
                            <i className="bi bi-caret-up-fill" />
                          ) : (
                            <i className="bi bi-caret-down-fill" />
                          ) }
                        </div>
                        {
                          item.isOpen &&
                            <div className='answer-content fs-5'>
                              { parse(parseContent(item.answer)) }
                            </div>
                        }
                        <hr />
                      </div>
                  ))
                }
              </div>
            )
          }
        </div>
      </div>
      <div className='chat-window__footer d-flex flex-column mt-2 mx-2 gap-2'>
        { report.length === 0 && !disable && !sampleClick &&
             <div className="mx-auto col-lg-8 col-xl-6"> <Samples onClick={ sampleHandler } /> </div>
        }
        <div
          className={ cnames(
            'textarea-wrapper mx-auto col-12 col-lg-10 col-xl-9 position-relative',
            inputValue.length === 0 ? 'error' : '',
          ) }
        >
          <textarea
            value={ inputValue }
            onChange={ handleChange }
            onKeyDown={ onEnterPress }
            placeholder="Ask your question..."
            className="form-control fs-4 p-4"
            rows={ 1 }
            ref={ textRef }
          />
          <div
            onClick={ disable ? () => {} : onSubmit }
            className="svg-button position-absolute top-50 translate-middle-y fs-2"
          >
            <i className="bi bi-cursor-fill" />
          </div>
        </div>
        <p className='mx-auto privacy text-center'>
        Please always double-check the responses from askWMO and verify the references.
        The database for the askWMO pilot project is limited to a selected number of publications.
        </p>
      </div>
    </div>
  );
};