import React from 'react';

export const Database: React.FC = () => (
  <div className="database chat-window mx-sm-auto mx-2 col-lg-9 fs-5">
    <h3>Under Development askWMO Database (Last update 6 May 2024)</h3>
    <a
      href="https://storage.googleapis.com/askwmo_pdfs/D_INFCOM.pdf"
      target="_blank"
    >INFCOM
    </a>
    <a
      href="https://storage.googleapis.com/askwmo_pdfs/E_SERCOM.pdf"
      target="_blank"
    >SERCOM
    </a>
    <a
      href="https://storage.googleapis.com/askwmo_pdfs/F_Technical_Regulations.pdf"
      target="_blank"
    >Technical Regulations
    </a>
    <a
      href="https://storage.googleapis.com/askwmo_pdfs/G_Manuals-Annexes%20to%20TRs.pdf"
      target="_blank"
    >Manuals-Annexes to TRs
    </a>
    <a
      href="https://storage.googleapis.com/askwmo_pdfs/H_Cg.pdf"
      target="_blank"
    >Cg
    </a>
    <a
      href="https://storage.googleapis.com/askwmo_pdfs/I_Ec.pdf"
      target="_blank"
    >Ec
    </a>
    <a
      href="https://storage.googleapis.com/askwmo_pdfs/J_Guides.pdf"
      target="_blank"
    >Guides
    </a>
    <a
      href="https://storage.googleapis.com/askwmo_pdfs/K_United_Science.pdf"
      target="_blank"
    >United Science
    </a>
    <a
      href="https://storage.googleapis.com/askwmo_pdfs/C_Rules%20of%20Procedure.pdf"
      target="_blank"
    >Rules of Procedure
    </a>
    <a
      href="https://storage.googleapis.com/askwmo_pdfs/B_Basic%20Document.pdf"
      target="_blank"
    >Basic Document
    </a>
    <a
      href="https://storage.googleapis.com/askwmo_pdfs/A_Regional%20Association%20Reports.pdf"
      target="_blank"
    >Regional Association Reports
    </a>
    <p className='my-4'>
      <b>To request the addition of new databases, please contact:</b>

      <p>Saeid A. Vaghefi</p>
      <p>AI and Climate Consultant (he/him, Dr.)</p>
      <p>World Meteorological Organization │ 7bis, avenue de la Paix, 1211 Geneva 2, Switzerland</p>
      <p>Email:<a href="mailto:svaghefi@wmo.int">svaghefi@wmo.int</a></p>
    </p>
  </div>
);
