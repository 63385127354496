import { API_URI, TOKEN } from './consts';
import { OrgData, UserFilter } from './types';

export const parseContent = (content: string) => {
  const regex = /(http[s]?:\/\/[^\s]+);/g;
  const parsedContent = content.replace(regex, (match) =>
    `<a class='ms-1' href="${match.replace(';', '')}" target="_blank">[ref]</a><br>`);
  return parsedContent.replace(/\n/g, '<br>');
};

export const formatWMONumbers = (input: string) => {
  const numbers = input.split(',').map(num => num.trim());
  const formattedNumbers = numbers.map(num => `WMO-No. ${num}`);
  return formattedNumbers.join(',');
};

export const fetchResults = async (
  inputValue: string,
  selectedOption: string,
  selectedResource: string[],
  inputNoValue: string,
  topKValue: number,
) => {
  let userFilter: UserFilter = [];

  if (inputNoValue.length > 0) {
    const questionTypesArray = formatWMONumbers(inputNoValue).split(',').map(type => type.trim());
    // Add questionTypesArray to userFilter
    questionTypesArray.forEach(type => {
      userFilter.push({ TableName: 'wmo_no', Value: type, ValueType: 'string' });
    });
  } else {
    // Convert userQuestionTypes to an array
    const questionTypesArray = selectedResource.map(type => type.trim());
    // Add questionTypesArray to userFilter
    questionTypesArray.forEach(type => {
      userFilter.push({ TableName: 'org', Value: type, ValueType: 'string' });
    });
  }

  const report: OrgData[] = [];

  // const fetchResultsPromises = userQuestionTypes.map(async (userQuestionType, index) => {
  const fetchResultsPromises = async () => {
    try {
      const formdata = new FormData();
      // formdata.append('user_question_energy', inputValue);
      // formdata.append('user_question_type', String(userQuestionTypes));
      // formdata.append('user_gpt_core', selectedOption);
      // formdata.append('user_top_k', topKValue.toString());

      formdata.append('UserQuestion', inputValue);
      // formdata.append('UserQuestionType', String(userQuestionTypes));
      formdata.append('UserFilter', JSON.stringify(userFilter));
      formdata.append('UserGptCore', selectedOption);
      formdata.append('UserTopK', topKValue.toString());
      // formdata.append('Company', 'wmo');
      formdata.append('Token', TOKEN);

      const requestOptions: RequestInit = {
        method: 'POST',
        body: formdata,
      };

      // // Add a 10-second wait before each fetch request for dev
      // await new Promise((resolve) => setTimeout(resolve, index * 0));

      const response = await fetch(API_URI, requestOptions);

      // await fetch('https://aiapicore.com/api/ChatResults', requestOptions)
      //   .then((res: any) => alert(res.json()))
      //   // eslint-disable-next-line no-console
      //   .then((result) => console.log(result))
      //   .catch((error) => console.error(error));

      const data: any = await response.json();
      data.forEach((orgData: any) => {
        if (typeof (orgData) === 'string')
          orgData = JSON.parse(orgData);

        report.push({ name: orgData.chatResponseType, answer: orgData.chatResponse, isOpen: false });
      });

      // const data: OrgData[] = await response.json();
      // data.forEach((orgData) => {
      //   report.push({ name: orgData.name, answer: orgData.answer, isOpen: false });
      // });

    } catch (error) {
      console.error(error);
    }
  };

  try {
    // await Promise.all(fetchResultsPromises);
    await fetchResultsPromises();
  } catch (error) {
    console.error(error);
  }

  return report;
};